import {UrlParameterTypes} from '@dv/kitadmin/core/uirouter';
import type {KitAdminState} from '@dv/kitadmin/models';
import {WILDCARD_TOKEN} from '@dv/shared/angular';
import {PERMISSION} from '@dv/shared/authentication/model';
import moment from 'moment';

export const WORK_TIME_CONTROLLING_MANDANT_STATE = {
    name: 'base.work-time-controlling-mandant',
    url: '/arbeitszeitkontrolle',
    data: {
        permission: PERMISSION.MODULE.WORK_TIME_CONTROLLING,
    },
} satisfies KitAdminState;

export const CONTROL_STATE = {
    name: `${WORK_TIME_CONTROLLING_MANDANT_STATE.name}.control`,
    url: '/kontrolle?:date',
    data: {
        subtitle: 'WORK_TIME_CONTROLLING.CONTROL.TITLE',
        permission: PERMISSION.WORK_TIME_CONTROLLING.WORK_TIME_CONTROLLING_TABLE_VIEW + WILDCARD_TOKEN,
    },
    params: {
        date: {
            type: UrlParameterTypes.MOMENT_DATE,
            dynamic: true,
        },
    },
    paramDeclaration: {
        date: moment,
    },
} satisfies KitAdminState;

export const CONFIG_STATE = {
    name: `${WORK_TIME_CONTROLLING_MANDANT_STATE.name}.config`,
    url: '/config',
    data: {
        subtitle: 'WORK_TIME_CONTROLLING.CONFIG.TITLE',
        permission: [
            PERMISSION.MODULE.WORK_TIME_CONTROLLING,
            PERMISSION.WORK_TIME_CONTROLLING.MANAGE_LOCK_CONFIG + WILDCARD_TOKEN,
        ],
    },
} satisfies KitAdminState;

export const SETTINGS_STATE = {
    name: `${WORK_TIME_CONTROLLING_MANDANT_STATE.name}.settings`,
    url: '/einstellungen',
    data: {
        subtitle: 'WORK_TIME_CONTROLLING.SETTINGS.TITLE',
    },
} satisfies KitAdminState;

export const WORK_TIME_MODELS_STATE = {
    name: `${WORK_TIME_CONTROLLING_MANDANT_STATE.name}.arbeitszeitmodell`,
    url: '/arbeitszeitmodell',
    data: {
        subtitle: 'WORK_TIME_CONTROLLING.WORK_TIME_MODEL.TITLE',
        permission: PERMISSION.WORK_TIME_CONTROLLING.WORK_TIME_MODEL_MANAGE + WILDCARD_TOKEN,
    },
} satisfies KitAdminState;

export const ABSENCE_TYPES_STATE = {
    name: `${WORK_TIME_CONTROLLING_MANDANT_STATE.name}.absencetypes`,
    url: '/absenztypen',
    data: {
        subtitle: 'WORK_TIME_CONTROLLING.ABSENCE_TYPES.TITLE',
    },
} satisfies KitAdminState;

export const REGULATIONS_STATE = {
    name: `${WORK_TIME_CONTROLLING_MANDANT_STATE.name}.regulations`,
    url: '/reglemente',
    data: {
        subtitle: 'WORK_TIME_CONTROLLING.REGULATION.TITLE',
        permission: `${PERMISSION.WORK_TIME_CONTROLLING.REGULATIONS_VIEW}general`,
    },
} satisfies KitAdminState;

export const REGULATION_FORM_STATE = {
    name: `${WORK_TIME_CONTROLLING_MANDANT_STATE.name}.regulation-create`,
    url: '/reglement-erstellen',
    data: {
        permission: PERMISSION.WORK_TIME_CONTROLLING.REGULATIONS_MANAGE + WILDCARD_TOKEN,
    },
} satisfies KitAdminState;

export const REGULATION_DETAIL_STATE = {
    name: `${WORK_TIME_CONTROLLING_MANDANT_STATE.name}.regulation-detail`,
    url: '/reglement/:id',
    data: {
        permission: `${PERMISSION.WORK_TIME_CONTROLLING.REGULATIONS_VIEW}general`,
    },
} satisfies KitAdminState;

